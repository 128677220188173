<template>
	<section class="perform">
		<quote-title label="金枝走演" />
		<br>
		<div class="row-grid" align-y="center" align-x="center" gutter-x="20" gutter-y="10">
			<div v-for="({ to, label }, i) in tabs" :key="i">
				<router-link :to="`/perform/${to}`" class="tab">{{label}}</router-link>
			</div>
		</div>
		<br>
		<br>
		<transition name="fade" mode="out-in">
			<router-view style="width:100%; max-width:1000px; margin:auto;" />
		</transition>
	</section>
</template>

<script>
export default {
	components: {
		Radio: () => import("@/components/radio")
	},
	data() {
		return {
			tabs: [
				{ to: "start", label: "緣起" },
				{ to: "promoter", label: "發起人的話" },
				{ to: "director", label: "藝術總監的話" },
				{ to: "event", label: "場次紀錄" },
				{ to: "support", label: "支持金枝走演" }
			]
		}
	}
}
</script>

<style lang="scss" scoped>
.perform {
	padding: 50px 5%;
	position: relative;
	background-size: 100%;
	background-repeat: no-repeat;
	background-image: url("../../../assets/background/brush1.png"),
		url("../../../assets/background/dot1.png");
	background-position: center 60%, center 60%;
}
a.tab {
	display: block;
	color: #fff;
	transition: 0.2s;
	padding: 5px 20px;
	letter-spacing: 2px;
	border-radius: 999px;
	background: $theme-light;

	&:hover,
	&.router-link-active {
		box-shadow: 3px 5px 10px #999;
	}

	&.router-link-active {
		background: $theme;
	}
}
</style>